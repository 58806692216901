import React from 'react';

export default () => (
  <>
    <div
      style={{ marginBottom: '5%', marginTop: '5%' }}
      className="container h4"
    >
      <p>
        Protecting the privacy on the Internet is crucial to the future of
        Internet-based business and Internet economy. E-Glas d.o.o., Miroslava
        Krleže 4, 51000 Rijeka, Croatia, European Union, VAT No. HR 01085855307
        (hereinafter referred to as “Company”) has created this Privacy Policy
        to demonstrate commitment to the individual`s right to data protection
        and privacy. This Privacy Policy outlines how Company handles
        information that can be used to directly or indirectly identify an
        individual ("Personal Data").
      </p>
      <h2>1. General information</h2>
      <p>
        <b>When does this Privacy Policy apply?</b>
      </p>
      <p>
        This Privacy Policy applies to Personal Data that you provide to Company
        or which is derived from the Personal Data as outlined below.
      </p>
      <p>
        <b>Data controller and DPO</b>
      </p>
      <p>
        Company is the data controller of www.eglas.hr, www.kokolingo.com,
        www.kokolingo.hr app.us.kokolingo.com, app.kokolingo.co.uk and
        app.kokolingo.hr (hereinafter referred to as “Company Websites”).
      </p>
      <p>
        Company’s data protection officer (DPO) is Mr. Marijan Pap,
        attorney-at-law (privacy@eglas.hr).
      </p>
      <p>
        <b>What does Company do with my Personal Data?</b>
      </p>
      <p>
        Company will process the Personal Data provided hereunder only as set
        out in this Privacy Policy. Further information can be found in Sections
        2 and 3 below. Where the processing of your Personal Data is based on a
        statutory permission, you can find information on which Personal Data
        Company is processing or using for which purposes in Section 2 below.
        Where consent for the processing of your Personal Data is required you
        can find further information in Section 3 below.
      </p>
      <p>
        <b>Duration of processing of Personal Data</b>
      </p>
      <p>Company will store your Personal Data:</p>• Only for as long as is
      required to fulfil the purposes set out below or <br />
      • Until you object to Company’s use of your Personal Data (where Company
      has a legitimate interest in using your Personal Data) or <br />
      • Until you withdraw your consent (where you consented to Company using
      your Personal Data). However, where Company is required by mandatory law
      to retain your Personal Data longer or where your Personal Data is
      required for Company to assert or defend against legal claims, Company
      will retain your Personal Data until the end of the relevant retention
      period or until the claims in question have been settled.
      <br />
      <p>
        <b>Why am I required to provide Personal Data?</b>
      </p>
      <p>
        As a general principle, your granting of any consent and your provision
        of any Personal Data hereunder is entirely voluntary; there are
        generally no detrimental effects on you if you choose not to consent or
        to provide Personal Data. However, there are circumstances in which
        Company cannot take action without certain Personal Data, for example
        because this Personal Data is required to process your orders or provide
        you with access to the Company software or a web offering or newsletter.
        In these cases, it will not be possible for Company to provide you with
        what you request without the relevant Personal Data.
      </p>
      <p>
        <b>Where will my Personal Data be processed?</b>
      </p>
      <p>
        Company has affiliates and third-party service providers within the
        European Economic Area (the “EEA”) and in the United States of America.
        Company’s servers are located in Amazon AWS CloudFront zone us-east-1 in
        Virginia for US users and Amazon AWS CloudFront zone eu-central-1 in
        Frankfurt, Germany for EU and all other users.
      </p>
      <p>
        Whenever Company is using or otherwise processing your Personal Data for
        the purposes set out in this Privacy Policy, Company may transfer your
        Personal Data to other countries within the EEA.
      </p>
      <p>
        <b>What are your rights?</b>
      </p>
      • Right to access Personal Data - You have right to access the data
      collected on you by Company. Company will respond to that request within
      30 days. <br />
      • Right to rectification - You have right to request modification of your
      data, including the correction or errors and the updating of incomplete
      information. <br />
      • Right to erasure - You have right to stop all processing of your data
      and request your Personal Data be erased. <br />
      • Right to restrict data processing - You, under certain circumstances,
      have right to request that all processing of your Personal Data be
      stopped. <br />
      • Right to be notified – You have right to be informed about the uses of
      your Personal Data in a clear manner and be told the actions that can be
      taken if you feel your rights are being impeded. <br />
      • Right to data portability – You have right to request that your Personal
      Data file be sent electronically to you or a third party. Data will be
      provided in a commonly used, machine readable format, if doing so is
      technically feasible. <br />
      • Right to object - If your request to stop data processing is rejected by
      Company, you have right to object. <br />• Right to reject automated
      individual decision-making – You have right to refuse the automated
      processing of your Personal Data to make decisions about you if that
      significantly affects you or produces legal effects. <br />
      <p>
        If Company uses your Personal Data based on your consent or to perform a
        contract with you, you may further request from Company a copy of the
        Personal Data that you have provided to Company. In this case, please
        contact the email address privacy@eglas.hr and specify the information
        or processing activities to which your request relates, the format in
        which you would like this information, and whether the Personal Data is
        to be sent to you or another recipient. Company will carefully consider
        your request and discuss with you how it can best fulfill it.
      </p>
      <p>
        Furthermore, you can request that Company restricts your Personal Data
        from any further processing in any of the following events:
      </p>
      • You state that the Personal Data Company has about you is incorrect,
      (but only for as long as Company requires to check the accuracy of the
      relevant Personal Data). <br />
      • There is no legal basis for Company processing your Personal Data and
      you demand that Company restricts your Personal Data from further
      processing. <br />
      • Company no longer requires your Personal Data but you claim that you
      require Company to retain such data in order to claim or exercise legal
      rights or to defend against third party claims. <br />• In case you object
      to the processing of your Personal Data by Company (based on Company’s
      legitimate interest as further set out in Section 2 below) for as long as
      it is required to review as to whether Company has a prevailing interest
      or legal obligation in processing your Personal Data. <br />
      <p>
        <b>Right to lodge a complaint</b>
      </p>
      <p>
        If you believe that Company is not processing your Personal Data in
        accordance with the requirements set out herein or applicable EEA data
        protection laws, you can at any time lodge a complaint with the data
        protection authority of the EEA country in which you live or with the
        data protection authority of the country or state in which Company has
        its registered seat (Croatia).
      </p>
      <p>
        <b>Use of Company Websites by children</b>
      </p>
      <p>
        Company Websites are intended for use by children and minors under age
        of 16 only in the presence of an adult – parent, guardian or speech
        therapist.
      </p>
      <p>
        You are advised to use child’s nickname instead of real name during
        registration on Company Websites and in correspondence with Company as
        there is no need or requirement for Company to collect or process any
        Personal Data of children such as their real names to offer its
        services.
      </p>
      <p>
        <b>Links to other websites</b>
      </p>
      <p>
        Company Websites may contain links to external websites. Company is not
        responsible for the privacy practices or the content of websites outside
        Company. Therefore, Company recommends that you carefully read the
        privacy statements of such external websites.
      </p>
      <p>
        <b>Data breach procedures</b>
      </p>
      <p>
        If you believe that the processing of personal data that Company carries
        out is contrary to the regulations on personal data protection, please
        inform the Company’s Data Protection Officer at the email address
        privacy@eglas.hr.
      </p>
      <p>
        In the event of a personal data breach, Company will notify you and the
        supervisory authority by email within 72 hours, describing the extent of
        the breach, data covered, possible impact on Company’s services,
        Company’s planned data protection measures and limiting any adverse
        effects on you.
      </p>
      <p>
        At any time, you can send a complaint to the supervisory body of the
        Personal Data Protection Agency of Republic of Croatia (AZOP) regarding
        Company’s collection and processing of your Personal Data. AZOP’s
        contact details are: Selska cesta 136, 10 000 Zagreb, Croatia, Tel: +385
        1 4609 000, fax: +385 1 4609 099, Email: azop@azop.hr, Web:
        https://azop.hr/naslovna-english/ .
      </p>
      <h2>2. Where Company uses my Personal Data based on the law</h2>
      <p>
        In the following cases, Company is permitted to process your Personal
        Data under the applicable data protection law.
      </p>
      <p>Providing the requested software or services</p>
      <p>
        If you order software or services from Company, Company will use the
        Personal Data that you enter into the order or registration form
        (usually a subset of) your name, email address, postal address and
        telephone number. If payment is to be made to Company - IBAN number,
        credit or debit card number or other bank details may be needed to
        process your order or to provide the requested software or service. This
        may also include conversation data that you may enter via the chatbot or
        chat functionalities on Company Websites, contact forms, emails, or
        telephone conversations. In this Privacy Policy, “software and services”
        include (access to) Company Websites, applications, other content,
        non-marketing related newsletters, whitepapers, tutorials, trainings and
        events.
      </p>
      <p>
        If you participate in tutorials or trainings provided by Company,
        Company may also track your learning progress in order to make this
        information available to you. Furthermore, Company communicates on a
        regular basis by email with users who subscribe to Company’s services,
        and Company may also communicate by phone to resolve your complaints or
        investigate suspicious transactions. Company may use your email address
        to confirm your opening of an account, to send you notice of payments,
        to send you information about changes to Company’s software and
        services, and to send notices and other disclosures as required by law.
        Generally, users cannot opt out of these communications, which are not
        marketing-related but merely required for the relevant business
        relationship. With regard to marketing-related types of communication
        (i.e. emails and phone calls), Company will:
      </p>
      • Where legally required only provide you with such information after you
      have opted in. <br />
      • Provide you the opportunity to opt out if you do not want to receive
      further marketing-related types of communication from us. <br />
      <p>
        <b>Ensuring compliance</b>
      </p>
      <p>
        Company and its software, technologies, and services are subject to the
        export laws of various countries including, without limitation, those of
        the European Union and its member states, of the United Kingdom and of
        the United States of America. You acknowledge that, pursuant to the
        applicable export laws, trade sanctions, and embargoes issued by these
        countries, Company is required to take measures to prevent entities,
        organizations, and parties listed on government-issued sanctioned-party
        lists from accessing certain products, technologies, and services
        through Company Websites or other delivery channels controlled by
        Company. This may include:
      </p>
      • Automated checks of any user registration data as set out herein and
      other information a user provides about his or her identity against
      applicable sanctioned-party lists; <br />
      • Regular repetition of such checks whenever a sanctioned-party list is
      updated or when a user updates his or her information; <br />
      • Blocking of access to Company’s services and systems in case of a
      potential match; and <br />
      • Contacting a user to confirm his or her identity in case of a potential
      match. <br />
      <p>
        Furthermore, you acknowledge that any information required to track your
        choices regarding the processing or use of your Personal Data or receipt
        of marketing materials (that is to say, depending on the country in
        which the Company operates, whether you have expressly consented to or
        opted out of receiving marketing materials) may be stored and exchanged
        between members of the Company’s group or larger business entity as
        required to ensure compliance.
      </p>
      <p>
        <b>Company’s legitimate interest</b>
      </p>
      <p>
        Each of the use cases below constitutes a legitimate interest of Company
        to process or use your Personal Data. If you do not agree with this
        approach, you may object against Company’s processing or use of your
        Personal Data as set out below.
      </p>
      <p>
        Questionnaires and surveys. Company may invite you to participate in
        questionnaires and surveys. These questionnaires and surveys will be
        generally designed in a way that they can be answered without any
        Personal Data. If you nonetheless enter Personal Data in a questionnaire
        or survey, Company may use such Personal Data to improve its software
        and services.
      </p>
      <p>
        Creation of anonymized data sets. Company may anonymize Personal Data
        provided under this Privacy Policy to create anonymized data sets, which
        will then be used to improve its and its affiliates’ software and
        services.
      </p>
      <p>
        Recording of calls and chats for quality improvement purposes. In case
        of telephone calls or chat sessions, Company may record such calls or
        chat sessions in order to improve the quality of Company’s software and
        services.
      </p>
      <p>
        In order to keep you up-to-date/request feedback. Within an existing
        business relationship between you and Company, Company may inform you,
        where permitted in accordance with local laws, about its software or
        services (including webinars, seminars, conferences, workshops or
        events) which are similar or relate to such software and services you
        have already purchased or used from Company. Furthermore, where you have
        attended a webinar, seminar, conference, workshop or event of Company or
        purchased software or services from Company, Company may contact you for
        feedback regarding the improvement of the relevant webinar, seminar,
        conference, workshop, event, software or service.
      </p>
      <p>
        You may object to Company using Personal Data for the above purposes at
        any time by unsubscribing by sending email to privacy@eglas.hr. If you
        do so, Company will cease using your Personal Data for the above
        purposes (that is to say, under a legitimate interest set out above) and
        remove it from its systems unless Company is permitted to use such
        Personal Data for another purpose set out in this Privacy Policy or
        Company determines and demonstrates a compelling legitimate interest to
        continue processing your Personal Data.
      </p>
      <h2>3. Where Company uses my Personal Data based on my consent</h2>
      <p>
        In the following cases Company will only use your Personal Data as
        further detailed below after you have granted your prior consent into
        the relevant processing operations. If you re-open this Privacy Policy
        after you have initially one or more consents granted you will not only
        see the information related to the consents you have granted but the
        full privacy statement.
      </p>
      <p>
        <b>News about Company’s services</b>
      </p>
      <p>
        Subject to a respective provision and your consent, Company may use your
        name, email and postal address and telephone number as well as an
        interaction profile based on prior interactions with Company (prior
        purchases, participation in webinars, seminars, conferences, workshops
        or events or the use of (web) services displayed on the relevant Company
        Websites) in order to keep you up to date on the latest software-related
        announcements, software updates, software upgrades, special offers, and
        other information about Company’s software and services (including
        marketing-related newsletters and blogs) as well as events of Company
        and in order to display relevant content on Company Websites. In
        connection with these marketing-related activities, Company may provide
        a hashed user ID to third party operated business and social networks or
        other web offerings (such as Facebook, Instagram, Twitter, Google
        (YouTube) or LinkedIn) where this information is then matched against
        the social networks’ data or the web offerings’ own data bases in order
        to display to you more relevant information.
      </p>
      <p>
        Please refer to the privacy guidelines of the respective social network
        for information regarding the purpose, duration and scope of the data
        collection, the further processing and use of your data, your respective
        rights, and setting options to protect your privacy:
      </p>
      • Facebook: http://www.facebook.com/policy.php <br />
      • Instagram: https://help.instagram.com/519522125107875 <br />
      • Twitter: https://twitter.com/privacy?lang=de <br />
      • Google (YouTube): https://policies.google.com/privacy?hl=us <br />
      • LinkedIn: https://www.linkedin.com/legal/privacy-policy <br />
      <p>
        <b>Creating user profiles</b>
      </p>
      <p>
        Company offers you the option to use its web offerings including blogs
        and networks (such as Company’s business and social networks) linked to
        Company Websites that require you to register and create a user profile.
        User profiles provide the option to display personal information about
        you to other users, including but not limited to your name, photo,
        social media accounts, postal or email address, or both, telephone
        number, personal interests, skills, and basic information about your
        company.
      </p>
      <p>
        These profiles may relate to a single web offering of Company may also
        allow you to access other web offerings of Company or any of these
        (irrespective of any consent granted under the section “Forwarding your
        Personal Data to other Company partner and supplier companies.” below).
        It is, however, always your choice which of these additional web
        offerings you use and your Personal Data is only forwarded to them once
        you initially access them. Note that without your consent for Company to
        create such user profiles Company will not be in a position to offer
        such services to you where your consent is a statutory requirement that
        Company can provide these services to you.
      </p>
      <p>
        <b>Event profiling</b>
      </p>
      <p>
        If you register for a webinar, seminar, conference, workshop or event of
        Company, Company may share basic participant information (your name and
        email address) with other participants of the same webinar, seminar,
        conference, workshop or event for the purpose of communication and the
        exchange of ideas.
      </p>
      <p>
        <b>
          Forwarding your Personal Data to other Company partner and supplier
          companies
        </b>
      </p>
      <p>
        Company may transfer your Personal Data to other entities. In such
        cases, these entities will then use the Personal Data for the same
        purposes and under the same conditions as outlined in this Section 3.
        above.
      </p>
      <p>
        <b>Revocation of a consent granted hereunder</b>
      </p>
      <p>
        You may at any time withdraw a consent granted hereunder by
        unsubscribing by sending email to privacy@eglas.hr. In case of
        withdrawal, Company will not process Personal Data subject to this
        consent any longer unless legally required to do so. In case Company is
        required to retain your Personal Data for legal reasons your Personal
        Data will be restricted from further processing and only retained for
        the term required by law. However, any withdrawal has no effect on past
        processing of personal data by Company up to the point in time of your
        withdrawal. Furthermore, if your use of a Company offering requires your
        prior consent, Company will not be (any longer) able to provide the
        relevant service, offer or event to you after your revocation.
      </p>
    </div>
  </>
);
