import React from 'react';

export default () => (
  <div className="container">
    <p>
      This Kokolingo SaaS (Software as a Service) Agreement (hereinafter
      referred to as “Agreement”) is entered into between
    </p>
    <p>
      E-Glas d.o.o., Miroslava Krleže 4, 51000 Rijeka, Croatia, European Union,
      VAT No. HR 01085855307 (hereinafter referred to as “Company”) as owner of
      Kokolingo software for online practicing of speech disorders that is being
      used as the SaaS and provider of related professional services
      (hereinafter referred to as “Platform”) and Customer, as of Effective
      Date.
    </p>
    <p>
      Customer is exclusively an adult with whose full consent and
      responsibility (with all provisions of Agreement) and under whose
      supervision the service to a minor (person under the age of full legal
      responsibility) user is provided.
    </p>
    <p>
      These Terms and Conditions, together with Order Form entered between
      Customer and Company (or their respective Affiliates), constitute the
      “Agreement” between the parties.
    </p>
    <p>
      The Customer wishes to obtain access to the SaaS Services from the
      Company, which the Company agrees to provide as per the terms of
      Agreement.
    </p>
    <h2>1. Platform (Kokolingo software and services)</h2>
    <p>
      Purpose: Company agrees to provide Platform with Agreement to Customer.
    </p>
    <p>
      Platform consists of a subset or all of the following, based on the
      subscription model selected by Customer:
    </p>
    i. Access to Kokolingo software, available at app.us.kokolingo.com,
    app.kokolingo.co.uk or app.kokolingo.hr, depending of market <br />
    ii. Software maintenance <br />
    iii. Technical support <br />
    iv. Speech disorder therapy support <br />
    v. Training-the-trainers support <br />
    vi. Webinars, seminars, conferences, workshops and events <br />
    vii. Information support through emails, newsletter, blog and web articles{' '}
    <br />
    viii. Other services related to the optimal use of Kokolingo software.{' '}
    <br />
    <p>
      If Customer is a parent or a guardian of a minor, the use of Platform
      shall exclusively be for personal use to benefit the minor. In that case,
      Customer is considered to be Authorized User.
    </p>
    <p>
      If Customer is an organization or a speech disorder therapist, the use of
      Platform is exclusively for managing and providing service to their own
      customers who are then considered to be Authorized Users. The number of
      such Authorized Users per Customer shall not exceed the number specified
      by the subscription model for organizations or speech disorder therapists.
    </p>
    <p>
      Customer has the right to use Platform for the subscription term tied to
      selected subscription model, if the fee has been paid for the subscription
      model to Company in full.
    </p>
    <p>
      Changes to Platform: Company may, in its sole discretion, make any changes
      to any Platform that it deems necessary or useful to:
    </p>
    1. Maintain or enhance: <br />
    i. The quality or delivery of the Company’s products or services to its
    customers. <br />
    ii. The competitive strength of, or market for, the Company’s products or
    services. <br />
    iii. Platform’s cost efficiency or performance. <br />
    <h2>2. Comply with applicable law.</h2>
    <p>2. Platform Access and Authorized User</p>
    <p>
      Authorized User: Authorized User subscriptions are for designated
      Authorized Users and cannot be shared or used by more than one Authorized
      User, but may be reassigned to new Authorized User with Company’s
      permission.
    </p>
    <p>Authorized User Conditions to Use:</p>
    <p>As a condition to access and use of a Platform</p>
    i. Each Authorized User shall agree to abide by the terms of the Company’s
    end-user terms of use which it may adopt from time to time. <br />
    ii. Customers shall agree to abide by the terms of Agreement, or a subset
    thereof. <br />
    iii. Customers shall immediately notify the Company of any violation of the
    terms of any of the foregoing by any Authorized User upon becoming aware of
    such violation. <br />
    <p>Account Responsibility:</p>
    <p>Customer shall be responsible for:</p>
    i. All uses of any account that Customer has access to, whether or not the
    Customer has authorized the particular use or user, and regardless of
    Customer’s knowledge of such use. ii. Securing the account and passwords.
    <p>
      The Company shall not be responsible for any loss of information due to
      any irresponsible act such as loss of the password by Authorized User.
    </p>
    <h2>3. Confidentiality</h2>
    <p>
      All confidential information that is communicated to and obtained by the
      Company from the Customer in connection with providing Platform shall be
      held by the Company in full trust. At no time the Company shall use any
      confidential information obtained through conducting this Agreement either
      directly or indirectly, for personal benefit, or disclose or communicate
      such information in any manner.
    </p>
    <h2>4. Proprietary Rights</h2>
    <p>
      Ownership: Company (and/or its licensors) is the sole and exclusive owner
      of all rights, title and interest in and to Platform and Company reserves
      all rights, title and interest in and to the stated services not expressly
      granted under the Agreement. No ownership right is conveyed to Customer,
      irrespective of the use of terms such as “purchase” or “sale” in any Order
      Form. The Company shall own and retain all rights, title, and interest in:
    </p>
    i. Any kind of software, applications, inventions, services or other
    technology developed in connection with the Platform;
    <br />
    ii. All intellectual property and proprietary rights in and related to any
    of the foregoing subscription models.
    <br />
    <p>
      Customer Data: Customer hereby grants the Company a non-exclusive,
      transferable, sublicensable, worldwide, and royalty-free license to use
      and otherwise exploit Customer (as well as Customer’s Authorized Users’)
      data to provide the Platform to Customer hereunder and as necessary or
      useful to monitor and improve Platform during and after Agreement period.
      To avoid any doubt, the Company shall use, reproduce and disclose
      Platform, information, data, and material that is anonymized,
      de-identified, or otherwise rendered not reasonably associated or linked
      to Customer, all of which information, data, and material, shall be owned
      by Company.
    </p>
    <p>
      Intellectual Property: The Company shall retain all the subscription
      models, including all documentation, modifications, improvements,
      upgrades, derivative works, and all other Intellectual Property rights in
      connection with Platform, including the Company's name, logos and
      trademarks reproduced through Platform.
    </p>
    <h2>5. Payment Terms, Fees</h2>
    <p>
      The Customer shall pay the Company the then-applicable fees described in
      an Order Form, in accordance with the terms set forth therein (“Fees”).
    </p>
    i. Free Trial: If Customer has registered for a trial use of Platform,
    Customer may access Platform for the free trial period specified by Company.
    Access to Platform during this period are free on offered plan.
    <br />
    ii. Fees: The Client agrees to pay all fees or charges to its account in
    accordance with the fees, charges, and billing terms in effect at the time a
    fee or charge is due and payable as a consideration for the provided
    Platform. If Customer does not pay the amounts due within 7 days of the due
    date, Company may suspend or terminate Platform, or both.
    <br />
    iii. Term and Renewal: Platform is valid for the subscription term described
    in the Order Form from Effective Date. At the end of the subscription term
    or after the expiration of the free trial period, Customer will be contacted
    by Company with a subscription renewal offer. However, Customer does not
    need to renew nor pay any fee unless Customer wishes to renew the
    subscription.
    <br />
    iv. Customer choses to pay the subscription from month to month or in
    advance for three, six or nine months, receiving 5%, 10% and 15% discount
    respectively. Company will automatically generate an invoice or bill the
    Customer’s credit or debit card.
    <br />
    v. Refunds: A refund is possible for the Customer’s last payment. For
    example, if Customer has paid subscription in advance for 6 months and is
    not satisfied with Platform, after contacting Company, it shall refund the
    full amount that Customer has paid with the last payment to Company. The
    deadline for requesting a refund is 30 days from the date of payment.
    <br />
    vi. Upgrade/Downgrade: For any upgrade or downgrade in subscription model,
    Customer will automatically be charged the new rate on the next billing
    cycle within the same subscription term.
    <br />
    vii. Payment by Credit or Debit Card: If Customer is paying by credit or
    debit card, Customer authorizes Company to charge credit or debit card or
    bank account for all fees payable at the beginning of the subscription term
    and any renewal subscription term(s), including upgrades. Customer further
    authorizes Company to use a third party to process payments, and consent to
    the disclosure of payment information to such third party.
    <br />
    viii. Payment by Invoice: If Customer is paying by invoice, Company will
    invoice Customer at the beginning of the subscription term and at the
    beginning of each subsequent billing term through email. All amounts
    invoiced are due and payable within seven (7) days from the date of the
    invoice.
    <br />
    <h2>6. Software service level commitment, Communications</h2>
    <p>
      i. Technical Support: Technical support is included in the fee and
      available to Customer and its Authorized Users. Live chat and email
      support is available from 8 am to 11 pm Eastern Standard Time (EST),
      Monday through Saturday, excluding US national holidays.
    </p>
    <p>
      ii. Communications: By creating a user account on Platform, Authorized
      User shall receive emails or postal letters as part of the selected
      subscription model. Customer shall also be asked to subscribe to
      newsletters, marketing or promotional materials and other information
      Company may send. Customer may opt in or opt out of receiving any, or all,
      of these communications from Company by following the
      subscribe/unsubscribe link or instructions provided in email.
    </p>
    <h2>7. Termination</h2>
    i. Customer may terminate Agreement for any reason ant any given moment.
    <br />
    ii. The Company may terminate agreement with immediate effect by delivering
    notice of the termination to the Customer if the Customer fails to pay the
    invoice amount on time.
    <br />
    <h2>8. Indemnification</h2>
    <p>
      Company and Customer each agree to indemnify and hold harmless the other
      party, its respective affiliates, employees, and permitted successors and
      assigns against any losses, claims, damages, penalties, liabilities,
      punitive damages, expenses, reasonable legal fees of whatsoever kind or
      amount, which result from the negligence of or breach of Agreement by the
      indemnifying party, its respective affiliate or successors and any assign
      that occurs in connection with Agreement. This section remains in full
      force and effect even after the termination of Agreement.
    </p>
    <h2>9. Limitation of Liability, Disclaimer</h2>
    <p>
      To the maximum extent permitted by applicable law, in no event will
      Company (nor any of its agents, affiliates, licensors or suppliers) be
      liable for any indirect, punitive, incidental, special, or consequential
      damages, or cost of procurement of substitute goods, services or
      technology, arising out of or in any way connected with the use of
      Platform or anything provided in connection with Agreement.
    </p>
    <p>
      Customer shall be aware that the Platform cannot replace medical and
      professional services, nor it is guaranteed that Platform will be
      effective, unharmful or able to resolve issues of users with speech
      disorders.
    </p>
    <p>
      Customer shall also be aware that Platform may not be uninterrupted or
      error-free.
    </p>
    <p>
      To the maximum extent permitted by applicable law, in no event will
      Company’s total liability under Agreement (including all Order Forms),
      whether based in Agreement, tort (including negligence or strict
      liability), or otherwise, exceed, in the aggregate, the fees paid to
      company hereunder in the twelve (12) month period ending on the date of
      the first event giving rise to liability under Agreement.
    </p>
    <p>
      Disclaimer. Company makes no representations or warranties, express or
      implied, and Platform is provided “AS IS.”
    </p>
    <p>
      Company hereby disclaims all other warranties, including but not limited
      to any:
    </p>
    i. implied warranty of merchantability or fitness for a particular purpose
    or non-infringement;
    <br />
    ii. implied warranty arising from course of performance, course of dealing,
    or usage of trade.
    <br />
    <p>
      Customer acknowledges that Company does not control the transfer or
      transmission of data over communications facilities, including the
      internet, and that the services may be subject to limitations, delays, and
      other problems inherent in the use of such communications facilities.
    </p>
    <h2>10. Miscellaneous</h2>
    i. Assignability: Neither party may assign Agreement or the rights and
    obligations thereunder to any third party without the prior express written
    approval of the other party which shall not be unreasonably withheld.
    <br />
    ii. Notices: Any notice required to be given to the Customer shall be
    delivered by certified email or postal address.
    <br />
    iii. Force Majeure: Neither party shall be liable for any failure in
    performance of the obligation under Agreement due to cause beyond that
    party’s reasonable control (including and not limited to any pandemic, fire,
    strike, act or order of public authority, and other acts of God) during the
    pendency of such event.
    <br />
    iv. Modification: No modification of Agreement shall be made unless in
    writing, signed by both parties.
    <br />
    v. Severability: If any term, clause, or provision hereof is held invalid or
    unenforceable by a court of competent jurisdiction, all other terms will
    remain in full force and effect until the Agreement termination.
    <br />
    vi. Governing Law and Jurisdiction: Agreement shall be governed by the laws
    of the Republic of Croatia (EU). If the disputes under Agreement cannot be
    resolved by Arbitration, they shall be resolved by litigation in the courts
    of the Republic of Croatia including the federal courts therein, and the
    parties all consent to the jurisdiction of such courts, agree to accept
    service of process by mail and hereby waive any jurisdictional or venue
    defences otherwise available to it.
    <br />
    vii. Legal and Binding Agreement: Agreement is legal and binding between the
    parties as stated above. The parties each represent that they have the
    authority to enter into Agreement.
    <br />
    viii. Entire Agreement: Agreement constitutes the entire understanding of
    the parties, and revokes and supersedes all prior agreements and contracts
    between the parties and is intended as a final expression of their
    Agreement. It shall not be modified or amended except in writing signed by
    the parties hereto and specifically referring to Agreement. Agreement shall
    take precedence over any other documents which may conflict with Agreement.
    <br />
    <p>Effective Date: {new Date().toLocaleDateString()}</p>
  </div>
);
